import React from 'react'

const PageNotFound404 = () => {
    return (
        <>
            Page Not Found   !!!
        </>
    )
}

export default PageNotFound404
